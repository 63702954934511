// @file Network requests related to User Group Wall Collaborator from surface
/* eslint-disable no-return-await, no-template-curly-in-string */
import { asciiSafeStringify } from '@@/bits/json_stringify'
import type { WallAccessRole } from '@@/enums'
import FetchableObject from '@@/surface/api/fetchable_object'
import { fetchJson } from '@@/surface/api_fetch'
import type {
  Id,
  UserGroupId,
  UserGroupWallCollaborator as UserGroupWallCollaboratorType,
  UserWallCollaborator,
  WallId,
} from '@@/types'
import type { JsonAPIResponse } from '@padlet/arvo'
import { HTTPMethod } from '@padlet/fetch'

class UserGroupWallCollaboratorApi extends FetchableObject {
  public static async invite({
    wallId,
    userGroupId,
    role,
  }: {
    wallId: WallId
    userGroupId: UserGroupId
    role: WallAccessRole | undefined
  }): Promise<JsonAPIResponse<UserGroupWallCollaboratorType>> {
    const url = `/api/8/walls/${wallId}/user-group-collaborators`
    return await fetchJson(url, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({
        wallId,
        userGroupId,
        role,
      }),
    })
  }

  public static async update({
    wallId,
    userGroupId,
    role,
  }: {
    wallId: Id
    userGroupId: Id
    role: WallAccessRole
  }): Promise<JsonAPIResponse<UserGroupWallCollaboratorType>> {
    const url = `/api/8/walls/${wallId}/user-group-collaborators/${userGroupId}`
    return await fetchJson(url, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ role }),
    })
  }

  public static async remove({
    wallId,
    userGroupId,
  }: {
    wallId: Id
    userGroupId: Id
  }): Promise<JsonAPIResponse<UserWallCollaborator>> {
    const url = `/api/8/walls/${wallId}/user-group-collaborators/${userGroupId}`
    return await fetchJson(url, {
      method: HTTPMethod.delete,
    })
  }
}

export { UserGroupWallCollaboratorApi }
